import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import { getCurrentUser } from '../actions/currentUser';
import Analysis from './Analysis';

const store = configureStore();
store.dispatch(getCurrentUser());

document.addEventListener('DOMContentLoaded', () => {
  render(
    <Provider store={store}>
      <Analysis />
    </Provider>,
    document.querySelector('#js-root'),
  );
});
