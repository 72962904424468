import React from 'react';
import PropTypes from 'prop-types';
import { Chessground } from 'chessground';
import { eventPosition } from 'chessground/dist/util';
import AnalysisSparePiece from './AnalysisSparePiece';

class AnalysisBoard extends React.Component {
  static allowDrop(event) {
    event.preventDefault();
  }

  constructor(props) {
    super(props);
    this.board = React.createRef();
    this.handleDrop = this.handleDrop.bind(this);
  }

  componentDidMount() {
    const { config, cgEventChange } = this.props;
    const cg = Chessground(this.board.current, config);

    cg.set({
      events: { change: cgEventChange },
    });

    window.cg = cg;
    // when window orientation is flipped
    window.addEventListener('orientationchange', () => {
      cg.redrawAll();
    }, false);

    // when window is resized
    window.addEventListener('resize', () => {
      cg.redrawAll();
    }, false);
  }

  handleDrop(event) {
    event.preventDefault();
    const { cgEventChange } = this.props;

    const elementId = event.dataTransfer.getData('text');
    const sparePiece = document.getElementById(elementId);
    const pos = eventPosition(event);
    const key = cg.getKeyAtDomPos(pos);

    const pieces = new Map();
    pieces.set(
      key,
      {
        color: sparePiece.getAttribute('data-color'),
        role: sparePiece.getAttribute('data-piece'),
        promoted: false,
      }
    );

    // use cg.setPieces instead of cg.newPiece because newPiece will not replace an existing piece
    cg.setPieces(pieces);
    cgEventChange();
  }

  render() {
    const { orientation } = this.props;
    const spareTop = orientation === 'white' ? 'black' : 'white';
    const spareBottom = orientation;

    return (
      <div className="board-editor">
        <div className="justify-content-center">
          <span className="spare-blank" />
          <AnalysisSparePiece color={spareTop} piece="king" />
          <AnalysisSparePiece color={spareTop} piece="queen" />
          <AnalysisSparePiece color={spareTop} piece="rook" />
          <AnalysisSparePiece color={spareTop} piece="bishop" />
          <AnalysisSparePiece color={spareTop} piece="knight" />
          <AnalysisSparePiece color={spareTop} piece="pawn" />
        </div>

        <div className="brown cg-wrap">
          <div
            ref={this.board}
            onDragOver={AnalysisBoard.allowDrop}
            onDrop={this.handleDrop}
          />
        </div>

        <div className="justify-content-center">
          <span className="spare-blank" />
          <AnalysisSparePiece color={spareBottom} piece="king" />
          <AnalysisSparePiece color={spareBottom} piece="queen" />
          <AnalysisSparePiece color={spareBottom} piece="rook" />
          <AnalysisSparePiece color={spareBottom} piece="bishop" />
          <AnalysisSparePiece color={spareBottom} piece="knight" />
          <AnalysisSparePiece color={spareBottom} piece="pawn" />
        </div>
      </div>
    );
  }
}

const chessgroundConfig = {
  animation: {
    duration: 500,
  },
  fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  turnColor: 'white',
  movable: {
    free: true, // all moves are valid - board editor
    color: 'both', // color that can move. white | black | both |
  },
  draggable: {
    showGhost: true,
    deleteOnDropOff: true,
  },
  drawable: {
    brushes: {
      green: { key: 'g', color: '#15781B', opacity: 1, lineWidth: 12 },
      paleGreen: { key: 'pg', color: '#15781B', opacity: 0.4, lineWidth: 4 },
      blue: { key: 'b', color: '#003088', opacity: 1, lineWidth: 12 },
      paleBlue: { key: 'pb', color: '#003088', opacity: 0.4, lineWidth: 4 },
      red: { key: 'r', color: '#882020', opacity: 1, lineWidth: 12 },
      yellow: { key: 'y', color: '#e68f00', opacity: 1, lineWidth: 12 },
    },
  },
  //  events: {
  //    change: function(orig, dest, metadata) {
  //      console.log('change');
  //    },
  //    move: function(orig, dest, metadata) {
  //      console.log('move');
  //    },
  //    dropNewPiece: function(orig, dest, metadata) {
  //      console.log('dropNewPiece');
  //    },
  //    select: function(orig, dest, metadata) {
  //      console.log('select');
  //    },
  //    insert: function(orig, dest, metadata) {
  //      console.log('insert');
  //    },
  //  },
};

// const shapeSet1 = [
//   { orig: 'a1', brush: 'blue' },
//   { orig: 'a5', brush: 'yellow' },
//   { orig: 'a6', brush: 'red' },
//   { orig: 'e2', dest: 'e4', brush: 'green' },
//   { orig: 'a6', dest: 'c8', brush: 'red' },
//   { orig: 'b6', dest: 'd8', brush: 'blue' },
//   { orig: 'f8', dest: 'f4', brush: 'yellow' },
//   { orig: 'h5', brush: 'green', piece: {
//     color: 'white',
//     role: 'knight'
//   }},
//   { orig: 'h6', brush: 'red', piece: {
//     color: 'black',
//     role: 'queen',
//     scale: 0.6
//   }}
// ];

AnalysisBoard.propTypes = {
  config: PropTypes.shape({}),
  cgEventChange: PropTypes.func,
  orientation: PropTypes.string,
};

AnalysisBoard.defaultProps = {
  config: chessgroundConfig,
  cgEventChange: null,
  orientation: 'white',
};

export default AnalysisBoard;
