import React from 'react';
import PropTypes from 'prop-types';

class AnalysisCastlingCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.handleToggleChange = this.handleToggleChange.bind(this);
  }

  handleToggleChange(event) {
    const { value, checked } = event.target;
    const { onToggleCastling } = this.props;
    onToggleCastling(value, checked);
  }

  render() {
    const { value, label, castling } = this.props;
    return (
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          value={value}
          checked={castling.includes(value)}
          onChange={this.handleToggleChange}
        />
        {label}
      </div>
    );
  }
}

export default AnalysisCastlingCheckbox;

AnalysisCastlingCheckbox.propTypes = {
  castling: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onToggleCastling: PropTypes.func.isRequired,
};
