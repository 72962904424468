import React from 'react';
import PropTypes from 'prop-types';

class AnalysisControls extends React.Component {
  constructor(props) {
    super(props);
    this.handleCalculate = this.handleCalculate.bind(this);
    this.handleMoveNav = this.handleMoveNav.bind(this);
  }

  handleCalculate(event) {
    const color = event.target.value;
    const { onCalculate } = this.props;
    onCalculate(color);
  }

  handleMoveNav(event) {
    const { value } = event.target;
    const { onMoveNav } = this.props;
    onMoveNav(value);
  }

  render() {
    const {
      flipBtn,
      resetBtn,
      clearBtn,
      moveNum,
      moveCount,
      children,
      Settings,
    } = this.props;
    let prevDisabled = false;
    let nextDisabled = false;
    if (moveNum === 0) prevDisabled = true;
    if (moveNum === moveCount) nextDisabled = true;

    return (
      <div className="card">
        <div className="card-body">
          <button type="button" className="btn btn-outline-secondary btn-block mr-1" onClick={this.handleCalculate} value="w">
            ♔ White to Move
          </button>
          <button type="button" className="btn btn-dark btn-block" onClick={this.handleCalculate} value="b">
            ♚ Black to Move
          </button>

          {children}

          <br />
          <br />
          <div className="clearfix" />
          <div className="btn-group p-1">
            <button type="button" className="btn btn-info mr-3" onClick={this.handleMoveNav} value="first" disabled={prevDisabled}>
              &lt;&lt;
            </button>
            <button type="button" className="btn btn-info mr-3" onClick={this.handleMoveNav} value="prev" disabled={prevDisabled}>
              &lt;
            </button>
            <button type="button" className="btn btn-info mr-3" onClick={this.handleMoveNav} value="next" disabled={nextDisabled}>
              &gt;
            </button>
            <button type="button" className="btn btn-info mr-3" onClick={this.handleMoveNav} value="last" disabled={nextDisabled}>
              &gt;&gt;
            </button>
          </div>
          <br />
          <br />

          {flipBtn}
          {resetBtn}
          {clearBtn}
          {Settings}
        </div>
      </div>
    );
  }
}

export default AnalysisControls;

AnalysisControls.propTypes = {
  children: PropTypes.node.isRequired,
  onCalculate: PropTypes.func.isRequired,
  onMoveNav: PropTypes.func.isRequired,
  moveNum: PropTypes.number.isRequired,
  moveCount: PropTypes.number.isRequired,
  Settings: PropTypes.node.isRequired,
  flipBtn: PropTypes.node.isRequired,
  resetBtn: PropTypes.node.isRequired,
  clearBtn: PropTypes.node.isRequired,
};
