import React from 'react';
import PropTypes from 'prop-types';

class AnalysisFen extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = { edit: false, value: '' };
  }

  handleClick() {
    const { edit } = this.state;
    this.setState({ edit: !edit });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleKeyPress(event) {
    const { cgEventChange } = this.props;
    if (event.key === 'Enter') {
      cg.set({ fen: event.target.value.trim() });
      cgEventChange();
    }
  }

  render() {
    const { edit, value } = this.state;

    let fenValue;
    if (typeof (cg) !== 'undefined') {
      fenValue = cg.getFen();
    } else {
      fenValue = '';
    }

    return (
      <h5>
        <span>FEN</span>

        <small className="ml-1">
          {edit ? (
            <input
              name="fen"
              type="text"
              value={value}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              className="form-control"
              placeholder="Paste FEN"
            />
          ) : (
            fenValue
          )}
        </small>

        {!edit && (
          <svg
            onClick={this.handleClick}
            className="bi bi-pencil"
            width="25"
            height="25"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M13.293 3.293a1 1 0 011.414 0l2 2a1 1 0 010 1.414l-9 9a1 1 0 01-.39.242l-3 1a1 1 0 01-1.266-1.265l1-3a1 1 0 01.242-.391l9-9zM14 4l2 2-9 9-3 1 1-3 9-9z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M14.146 8.354l-2.5-2.5.708-.708 2.5 2.5-.708.708zM5 12v.5a.5.5 0 00.5.5H6v.5a.5.5 0 00.5.5H7v.5a.5.5 0 00.5.5H8v-1.5a.5.5 0 00-.5-.5H7v-.5a.5.5 0 00-.5-.5H5z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </h5>
    );
  }
}

AnalysisFen.propTypes = {
  cgEventChange: PropTypes.func.isRequired,
};

export default AnalysisFen;
