import React from 'react';
import PropTypes from 'prop-types';

import { DragDropTouch } from '../src/drag_drop_touch';
/* eslint-disable import/no-unresolved */
import bK from 'images/pieces/bK.svg';
import bQ from 'images/pieces/bQ.svg';
import bR from 'images/pieces/bR.svg';
import bB from 'images/pieces/bB.svg';
import bN from 'images/pieces/bN.svg';
import bP from 'images/pieces/bP.svg';

import wK from 'images/pieces/wK.svg';
import wQ from 'images/pieces/wQ.svg';
import wR from 'images/pieces/wR.svg';
import wB from 'images/pieces/wB.svg';
import wN from 'images/pieces/wN.svg';
import wP from 'images/pieces/wP.svg';

const images = {
  bK, bQ, bR, bB, bN, bP, wK, wQ, wR, wB, wN, wP,
};


class AnalysisSparePiece extends React.Component {
  static handleDrag(event) {
    event.dataTransfer.setData('text', event.target.id);
  }

  getImage() {
    const { color, piece } = this.props;
    let imageName = color[0];

    if (piece === 'knight') {
      imageName += 'N';
    } else {
      imageName += piece[0].toUpperCase();
    }
    return images[imageName];
  }

  // TODO: remove harcoded width/height
  render() {
    const { color, piece } = this.props;

    return (
      <img
        className="spare"
        id={color + '_' + piece}
        alt={color + '_' + piece}
        data-color={color}
        data-piece={piece}
        width="50"
        height="50"
        onDragStart={AnalysisSparePiece.handleDrag}
        draggable="true"
        src={this.getImage()}
      />
    );
  }
}

AnalysisSparePiece.propTypes = {
  color: PropTypes.string,
  piece: PropTypes.string,
};

AnalysisSparePiece.defaultProps = {
  color: '',
  piece: '',
};

export default AnalysisSparePiece;
