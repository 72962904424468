import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import analysisReducer from '../reducers/analysisReducer';

const middlewares = [thunkMiddleware];

if (process.env.RAILS_ENV !== 'production') {
  middlewares.push(logger);
}

export default function configureStore(preloadedState) {
  return createStore(
    analysisReducer,
    preloadedState,
    applyMiddleware(...middlewares),
  );
}
