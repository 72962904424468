import React from 'react';
import PropTypes from 'prop-types';
import FeedbackModal from '../shared/FeedbackModal';

class AnalysisHeader extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.renderBestMove = this.renderBestMove.bind(this);
  }

  static renderWait() {
    return (
      <div className="alert alert-info" role="alert">
        Calculating, please wait...
      </div>
    );
  }

  handleClick(event) {
    const { sequenceMove } = this.props;
    sequenceMove(Number(event.target.value));
  }

  renderError() {
    const { error } = this.props;
    return (
      <div className="alert alert-danger" role="alert">
        {error}
        <FeedbackModal />
      </div>
    );
  }

  renderBestMove() {
    const {
      color,
      bestMove,
      score,
      sequence,
    } = this.props;
    let btnClass = color === 'w' ? 'btn-light' : 'btn-dark';
    const nextMoves = sequence.slice(1, 4);

    const bestMoveBtn = (
      <>
        The best move is
        &nbsp;
        <button
          type="button"
          className={'btn btn-sm ' + btnClass}
          value={0}
          onClick={this.handleClick}
        >
          {bestMove}
        </button>
      </>
    );

    const buttons = nextMoves.map((move, index) => {
      btnClass = btnClass === 'btn-light' ? 'btn-dark' : 'btn-light';
      return (
        <button
          key={move}
          type="button"
          className={'btn mr-2 btn-sm ' + btnClass}
          value={index + 1}
          onClick={this.handleClick}
        >
          {move}
        </button>
      );
    });

    return (
      <div className="alert alert-success" role="alert">
        {bestMoveBtn}
        &nbsp;
        Score:
        &nbsp;
        {score}
        &nbsp;
        &nbsp;
        &nbsp;
        {nextMoves.length > 0 ? 'sequence' : ''}
        &nbsp;
        {buttons}
        <FeedbackModal />
      </div>
    );
  }

  render() {
    const {
      bestMove,
      error,
      showWait,
    } = this.props;

    if (showWait) return AnalysisHeader.renderWait();
    if (error) return this.renderError();
    if (bestMove) return this.renderBestMove();

    return (
      <div className="alert alert-primary" role="alert">
        Enter your position onto the board below, press White or Black to move.
        <strong className="text-success"> We will calculate the best move.</strong>
      </div>
    );
  }
}

AnalysisHeader.propTypes = {
  color: PropTypes.string,
  bestMove: PropTypes.string,
  score: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  sequence: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  sequenceMove: PropTypes.func.isRequired,
  error: PropTypes.string,
  showWait: PropTypes.bool,
};

AnalysisHeader.defaultProps = {
  color: null,
  bestMove: null,
  sequence: [],
  score: null,
  error: null,
  showWait: false,
};


export default AnalysisHeader;
