import React from 'react';
import PropTypes from 'prop-types';
import axiosCSRF from '../util/axiosCSRF';

class AnalysisName extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.state = { edit: false, name: null };
  }

  componentDidMount() {
    const { position } = this.props;
    let name;

    if (position === null) {
      name = null;
    } else {
      name = position.name || position.id;
    }

    this.setState({ name });
  }

  handleClick() {
    const { edit } = this.state;
    this.setState({ edit: !edit });
  }

  handleChange(event) {
    this.setState({ name: event.target.value });
  }

  handleUpdate(event) {
    if (event.key === 'Enter') {
      const { position } = this.props;
      const { name } = this.state;

      this.setState({ edit: false, name });

      axiosCSRF
        .put(
          '/api/v1/analysis/' + position.id,
          { position: { name } },
        )
        .catch((error) => {
          console.log(error);
        });
    }
  }

  render() {
    const { edit, name } = this.state;

    if (name === null || name === undefined) return '';

    return (
      <h5>
        <span>Name</span>

        <small className="ml-1">
          {edit ? (
            <input
              name="name"
              type="text"
              value={name}
              onChange={this.handleChange}
              onKeyPress={this.handleUpdate}
              className="form-control"
            />
          ) : (
            name
          )}
        </small>

        {!edit && (
          <svg
            onClick={this.handleClick}
            className="bi bi-pencil"
            width="25"
            height="25"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M13.293 3.293a1 1 0 011.414 0l2 2a1 1 0 010 1.414l-9 9a1 1 0 01-.39.242l-3 1a1 1 0 01-1.266-1.265l1-3a1 1 0 01.242-.391l9-9zM14 4l2 2-9 9-3 1 1-3 9-9z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M14.146 8.354l-2.5-2.5.708-.708 2.5 2.5-.708.708zM5 12v.5a.5.5 0 00.5.5H6v.5a.5.5 0 00.5.5H7v.5a.5.5 0 00.5.5H8v-1.5a.5.5 0 00-.5-.5H7v-.5a.5.5 0 00-.5-.5H5z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </h5>
    );
  }
}

AnalysisName.propTypes = {
  position: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

AnalysisName.defaultProps = {
  position: null,
};

export default AnalysisName;
